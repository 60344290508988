<template>
  <div class="app-container">
    <h2>
      Hey, {{ username }}
    </h2>

    <el-table
      :data="stores"
      :row-class-name="tableRowClassName"
    >
      <el-table-column
        prop="name"
        label="Store Name"
        width="240"
      />

      <el-table-column
        prop="publicationCount"
        label="Publication Count"
        width="180"
      />
    </el-table>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { publicationCount } from '@/api/store';

  export default {
    name: 'Dashboard',
    data() {
      return {
        stores: [],
      };
    },
    computed: {
      ...mapGetters({
        username: 'account/username',
      }),
    },
    created() {
      this.getPublicationCount();
    },
    methods: {
      async getPublicationCount() {
        const response = await publicationCount();
        this.stores = response?.stores || [];
      },
      tableRowClassName({ row }) {
        if (row.publicationCount === 0) {
          return 'no-publications';
        }

        return '';
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../styles/views/_dashboard.scss';
</style>

<style lang="scss">
  .el-table .no-publications {
    color: #ff4040;
  }
</style>
