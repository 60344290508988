import Vue from 'vue';

export function listStores(params) {
  return Vue.$http({
    url: '/stores/listStores',
    method: 'get',
    params,
  });
}

export function getStore(params) {
  return Vue.$http({
    url: '/stores/getStore',
    method: 'get',
    params,
  });
}

export function getAllStores(params) {
  return Vue.$http({
    url: '/stores/getAllStores',
    method: 'get',
    params,
  });
}

export function updateStore(data) {
  return Vue.$http({
    url: '/stores/updateStore',
    method: 'post',
    data,
  });
}

export function createStore(data) {
  return Vue.$http({
    url: '/stores/createStore',
    method: 'post',
    data,
  });
}

export function deleteStore(data) {
  return Vue.$http({
    url: '/stores/deleteStore',
    method: 'post',
    data,
  });
}

export function publicationCount(params) {
  return Vue.$http({
    url: '/stores/publicationCount',
    method: 'get',
    params,
  });
}
